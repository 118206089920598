import React from 'react';
import Img from 'gatsby-image';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql, } from "gatsby"

const Menu = () => {

    const { allContentfulMenuCard } = useStaticQuery(graphql`
        query {
            allContentfulMenuCard {
                edges {
                    node {
                        price
                        menuName
                        active
                        content {
                            content
                        }
                        menuImages {
                            fluid {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    return (
        <Layout>
            <SEO title="Menú" />
            <div id="menuContentElem">
                <div style={{height:'95%'}}>
                    <h1>Menú de esta semana</h1>
                    <div id="menuList">
                        {
                            (allContentfulMenuCard.edges.length > 0) &&
                            allContentfulMenuCard.edges.map((elem) => 
                            <>
                                <div class="menuElement">
                                    <div style={{width:'50%', marginRight: '1%'}}>
                                        <Img fluid={ elem.node.menuImages[0].fluid } style={{height: '100%'}}/>
                                    </div>
                                    <div className='menuDesc' style={{backgroundColor:'white', width:'50%', height:'100%'}}>
                                        <h1>{elem.node.menuName}</h1>
                                        <div style={{overflowY: 'scroll'}}>
                                            {elem.node.content.content}
                                        </div>
                                        <h2>{formatter.format(elem.node.price)}</h2>
                                    </div>
                                </div>
                                <div class="menuElement">
                                    <div style={{width:'50%', height:'100%', marginRight: '1%'}}>
                                        <Img fluid={ elem.node.menuImages[0].fluid } style={{height: '100%'}}/>
                                    </div>
                                    <div className='menuDesc' style={{backgroundColor:'white', width:'50%', height:'100%'}}>
                                        <h1>{elem.node.menuName}</h1>
                                        <div style={{overflowY: 'scroll'}}>
                                            {elem.node.content.content}
                                        </div>
                                        <h2>{formatter.format(elem.node.price)}</h2>
                                    </div>
                                </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Menu;